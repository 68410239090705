import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
	ColumnDataType,
	DisplayMode,
	FormatRule,
	FormattingRule,
	GeneralSettingDTO,
	PatientWorkflow,
	Profile,
	TableColumn,
	TableConfig,
	TableView,
	ViewerDTO,
	WorkflowFilter,
	WorkflowItem,
} from '../../model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, merge, of as observableOf, Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import moment from 'moment';
import {
	checkCondition,
	CommentsComponent,
	DeleteConfirmComponent,
	getDisplayStyle,
	hasPermission,
	LocalStorageService,
	PrescriptionComponent,
	SharedService,
} from '../../shared';
import { WorkflowService } from '../../workflow/workflow.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SchedulerService } from '../../scheduler/scheduler.service';
import { SettingService } from '../../setting/setting.service';
import { AppConfigService } from '../../app-config.service';
import { WsService } from '../../ws.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportingService } from '../../reporting/reporting.service';
import { dropWhile, get, map as _map, remove, sortBy, union } from 'lodash';
import { DateUtils, loadRLEScripts, StringUtils } from '../../utils';
import {
	catchError,
	debounceTime,
	delay,
	distinctUntilChanged,
	first,
	map,
	startWith,
	switchMap,
	tap,
} from 'rxjs/operators';
import { rowsAnimation } from '../../animations';
import FileSaver from 'file-saver';
import numeral from 'numeral';
import { PhysicianService } from '../physician.service';
import { EXCLUDED_WORKFLOW_COLUMNS } from '../physicians-list/table-conf';

const WF_TABLE_CONFIG_NAME = 'workflow';
declare let LPW: any;

@Component({
	selector: 'ft-physician-exams',
	templateUrl: './physician-exams.component.html',
	styleUrls: ['./physician-exams.component.scss'],
	animations: [rowsAnimation],
})
export class PhysicianExamsComponent
	implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
	@Input() physician: any;
	@Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();

	filterForm: FormGroup | undefined;

	displayedColumns = [];
	columnsToDisplay = [];
	availableColumns = [];

	dataSource = new MatTableDataSource<WorkflowItem>();
	resultsLength = 0;
	isLoadingResults = true;
	isRateLimitReached = false;

	osirixViewers: ViewerDTO[] = [];

	@ViewChild('date', { static: false }) _date: ElementRef;
	@ViewChild('filter', { static: true }) filter: ElementRef;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	sorting: { active: string; direction: 'asc' | 'desc' };

	modalities: any[] = ['CR', 'CT', 'DX', 'MG', 'MR', 'US', 'XA'];
	technicians: any[];
	physicians: any[];
	private wf = new WorkflowFilter();

	private newWindow: any;
	private readonly user: any;
	profile: Profile;
	canViewConfData: boolean;
	private examStatuses: string[];
	private workflowFilterSubject = new BehaviorSubject<WorkflowFilter>(
		new WorkflowFilter()
	);
	private query = new BehaviorSubject<string>(null);
	generalSetting: GeneralSettingDTO;
	meddreamViewer: ViewerDTO;
	viewers: ViewerDTO[] = [];
	workflowTableConfig: TableConfig;
	linesFormattingRules: FormattingRule[] = [];
	columnsFormattingRules: FormattingRule[] = [];

	tableColumns: TableColumn[] = [];
	styles = {};

	public totalExams: any;
	public defaultPageSize = 100;

	currencyFormat = 'DH';
	syngoviaActivated = false;
	public totalExamsHidden: any;
	currentDate: any; //= moment().format( 'LLLL');
	views: TableView[] = [
		new TableView(0, 0, 'WORKFLOW_VIEW'),
		new TableView(0, 0, 'PAYMENTS'),
	];
	todayFormat = 'HH:mm';
	defaultViewer: ViewerDTO;

	printers: any[] = [];
	public readonly numberFormat: string;

	private ws$: Subscription;

	private physicianChange: BehaviorSubject<number> =
		new BehaviorSubject<number>(null);
	patientsCount: number = 0;

	private static isConditionFilled(
		colDataType: ColumnDataType,
		formatRule: FormatRule,
		data: any,
		firstValue: any,
		secondValue: any
	): boolean {
		return checkCondition(
			colDataType,
			formatRule,
			data,
			firstValue,
			secondValue
		);
	}

	trackById = (index: number, item: any): string => item.patientID;

	constructor(
		private workflowService: WorkflowService,
		private router: Router,
		private route: ActivatedRoute,
		private dialog: MatDialog,
		private scheduler: SchedulerService,
		private localStorage: LocalStorageService,
		private setting: SettingService,
		private _config: AppConfigService,
		private shared: SharedService,
		private wsService: WsService,
		private translate: TranslateService,
		private fb: FormBuilder,
		private _service: PhysicianService,
		private snack: MatSnackBar,
		private reportingService: ReportingService
	) {
		this.currencyFormat = _config.currencyFormat;
		this.numberFormat = _config.numberFormat;
		this.syngoviaActivated = _config.syngoviaActivated;

		this.user = get(this.route.snapshot.data, 'user');
		this.profile = get(this.user, 'profile');
		this.canViewConfData = this.user.canViewConfidentialData;

		this.currentDate = moment().format(
			this._config.appLang === 'en' ? 'LL' : 'LLL'
		);

		const userId = this.user.id;

		this.setting
			.getTableConfig(WF_TABLE_CONFIG_NAME, userId)
			.subscribe(_tableConfig => {
				if (_tableConfig) {
					dropWhile(_tableConfig.tableColumns, {
						label: 'completedReportStatus',
					});
					this.workflowTableConfig = _tableConfig;

					this.tableColumns = get(_tableConfig, 'tableColumns', []);

					this.dispatchRules(_tableConfig.formattingRules);
					this._updateDisplayColumns(this.tableColumns);
				}
			});

		this.generalSetting = this._config.generalSetting;

		this.translate
			.get('EXAM_STATUSES')
			.subscribe(res => (this.examStatuses = res.split('_')));

		this.sorting = this.localStorage.getItem('wf_sorting') || {
			active: 'id',
			direction: 'asc',
		};
		this.defaultPageSize =
			Number(this.localStorage.getItem('wf_page_size')) || 50;

		this.createFilterForm();

		setTimeout(() => (this.ws$ = this.subscribeToWsTopic()), 2000);
	}

	private _updateDisplayColumns(tableColumns: TableColumn[]): void {
		this.availableColumns = sortBy(
			tableColumns.filter(
				it =>
					it.available &&
					!EXCLUDED_WORKFLOW_COLUMNS.includes(it.label)
			),
			'order'
		);

		this.displayedColumns = this.availableColumns.filter(
			(tc: TableColumn) => !tc.hidden
		);
		this.columnsToDisplay = union(
			[],
			_map(this.displayedColumns, 'value'),
			['action']
		);
	}

	ngOnChanges(changes: SimpleChanges): void {
		const { physician } = changes;
		if (physician.currentValue)
			this.physicianChange.next(physician.currentValue.id);
	}

	ngAfterViewInit() {
		if (this.isRemoteEyeViewer()) loadRLEScripts();

		this.buildQuery();
		this.resetPaginator();

		this.sort.sortChange.subscribe(_ => (this.paginator.pageIndex = 0));

		const observedFilters = [
			this.sort.sortChange.asObservable(),
			this.paginator.page.asObservable(),
			this.query.pipe(debounceTime(250)),
			this.physicianChange.asObservable(),
		];

		merge(...observedFilters)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;
					const query = this.query.getValue();

					return this._service.getWorkflow(
						this.paginator.pageSize || 50,
						this.paginator.pageIndex || 0,
						this.sort.active || 'id',
						this.sort.direction || 'desc',
						query,
						this.physicianChange.value
					);
				}),
				tap(data => {
					this.isLoadingResults = false;
					this.isRateLimitReached = false;
					this.resultsLength = data['totalElements'];
				}),
				map(data => data['content'] as WorkflowItem[]),
				catchError(() => {
					this.isLoadingResults = false;
					this.isRateLimitReached = true;
					return observableOf([]);
				})
			)
			.subscribe(data => {
				this.dataSource.data = data;

				this._service
					.countPatients(
						this.query.getValue(),
						this.physicianChange.getValue()
					)
					.subscribe(count => (this.patientsCount = count));

				this.getLinesFormattingStyles();
			});

		if (window.location.hostname.startsWith('fireris'))
			setTimeout(() => {
				this.filterForm.get('period').patchValue('1M');
				this.changeRange({ value: '1M' });
			});
	}

	ngOnDestroy() {
		this.ws$?.unsubscribe();
	}

	ngOnInit() {
		this.filter.nativeElement.focus();
		this.getTableViews();
		this.getViewers();
		this.shared.getPrinters().subscribe(data => (this.printers = data));
	}

	public printReportingTask(row, printer?: string) {
		const matSnackBarRef = this.snack.open(
			this.translate.instant('PRINTING_IN_PROGRESS'),
			'',
			{ duration: 10000 }
		);

		if (this.generalSetting.reportPrintMode === 'CHROME')
			this.reportingService
				.printSimpleReport(row.reportingTaskId, printer, 1)
				.subscribe(_ => matSnackBarRef.dismiss());
		else {
			this.reportingService
				.printCupsSimpleReport(row.reportingTaskId, printer, '1')
				.subscribe(ok => {
					matSnackBarRef.dismiss();
					if (ok['status'] !== 'ok')
						console.log('Cannot print the report');
					else
						this.snack.open(
							this.translate.instant('FINALIZING_PRINTING'),
							'',
							{ duration: 3000 }
						);
				});
		}
	}

	public printBooklet(row, printer?: string) {
		const matSnackBarRef = this.snack.open(
			this.translate.instant('PRINTING_IN_PROGRESS'),
			'',
			{ duration: 10000 }
		);

		if (this.generalSetting.reportPrintMode === 'CHROME')
			this.reportingService
				.printReport(row.reportingTaskId)
				.subscribe(_ => matSnackBarRef.dismiss());
		else {
			this.reportingService
				.printCupsReport(row.reportingTaskId, printer, 1)
				.subscribe(response => {
					if (response['status'] !== 'ok')
						alert('Cannot print the booklet');
					else {
						matSnackBarRef.dismiss();
						this.snack.open(
							this.translate.instant('FINALIZING_PRINTING'),
							'',
							{ duration: 4000 }
						);
					}
				});
		}
	}

	showPatientFolder = row =>
		this.router.navigate(['/patients/folder', row.patientId]);

	openReport(row: WorkflowItem, newWind?: boolean): void {
		localStorage.setItem('wf', JSON.stringify(this.wf));
		if (!row.reportingTaskId) return;
		if (newWind) {
			if (this.newWindow && !this.newWindow.closed) {
				this.newWindow.focus();
				this.newWindow.location.pathname = `/reporting/report-edition/${row.reportingTaskId}`;
			} else {
				this.newWindow = window.open(
					`/reporting/report-edition/${row.reportingTaskId}`,
					row.reportingTaskId.toString(),
					'toolbar=0,location=0,menubar=0,left'
				);

				this.newWindow.addEventListener(
					'beforeunload',
					() => (this.newWindow = null)
				);
			}
		} else
			this.router
				.navigateByUrl(
					`/reporting/report-edition/${row.reportingTaskId}`
				)
				.then(console.log);
	}

	launchOsirix(aeTitle: string, patientID: string, studyInstanceUID: string) {
		this.reportingService
			.launchOsirix(aeTitle, patientID, studyInstanceUID)
			.subscribe(console.log);
	}

	startExam(row) {
		this.scheduler.startExamByAN(row.accessionNumber).subscribe(res => {
			setTimeout(() => this.workflowFilterSubject.next(this.wf), 10000);
			this.snack.open(this.examStatuses[3], 'OK', { duration: 2000 });
		});
	}

	finishExam(row) {
		this.scheduler.finishExam(row.isrId).subscribe(next => {
			setTimeout(() => this.workflowFilterSubject.next(this.wf), 10000);
			this.snack.open(this.examStatuses[0], 'OK', { duration: 2000 });
		});
	}

	createPrescription(row) {
		const patient = {
			patientName: row.patientName,
			patientID: row.patientID,
		};
		const physician = { physician: this.user.fullName };
		this.dialog
			.open(PrescriptionComponent, {
				data: { patient, physician },
				width: '60%',
			})
			.afterClosed()
			.subscribe(res => console.log(res));
	}

	can = (row: any, action: string): boolean =>
		(this.profile[action] !== 'NONE' && !row.confidential) ||
		this.canViewConfData;
	cannot = (action: string): boolean => this.profile[action] === 'NONE';

	isGranted = (row: WorkflowItem, status: string): boolean =>
		hasPermission(status, row);

	private buildQuery() {
		this.workflowFilterSubject.subscribe((wf: WorkflowFilter) => {
			this.query.next(
				[
					wf.key.replace('@', ''),
					wf.dateRange,
					wf.technicianId,
					wf.physicianId,
					wf.patientStatuses,
					wf.reportStatuses,
					wf.modalities,
					wf.paymentStatuses,
					'false',
				].join('@')
			);
		});
	}

	columnFormattingRules(header: string): FormattingRule[] {
		return this.columnsFormattingRules
			? this.columnsFormattingRules.filter(
					it => it.targetColumn === header
				)
			: [];
	}

	private createFilterForm() {
		this.filterForm = this.fb.group({
			key: '',
			startDate: new Date(),
			endDate: new Date(),
			period: 'TODAY',
			modality: null,
			technicianId: null,
			physicianId: null,
			reportStatus: null,
			patientStatus: null,
			paymentStatus: null,
		});

		this.filterForm.valueChanges
			.pipe(distinctUntilChanged())
			.subscribe(value => this.buildWorkflowFilter(value));
	}

	changePeriod() {
		this.filterForm.get('period').patchValue('OT');
	}

	changeRange(e) {
		const dateRange = DateUtils.PeriodDateRange(e.value);
		this.filterForm.patchValue(dateRange);
		this.wf.dateRange = `${dateRange.startDate.format('YYYYMMDD')}-${dateRange.endDate.format('YYYYMMDD')}`;
		this.workflowFilterSubject.next(this.wf);
	}

	deleteExam(wf: WorkflowItem) {
		this.dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.subscribe(ok => {
				if (ok) {
					this.scheduler
						.deleteExams(wf.accessionNumber)
						.subscribe(res => {
							if (res)
								this.snack.open(
									this.translate.instant('EXAM_DELETED'),
									'Ok',
									{ duration: 2000 }
								);
						});
				}
			});
	}

	private resetPaginator = () =>
		this.query.subscribe(() => (this.paginator.pageIndex = 0));

	getColumnStyle(
		colType: ColumnDataType,
		column: TableColumn,
		row: PatientWorkflow | WorkflowItem
	): any {
		if (!this.columnsFormattingRules) return;
		const rule = this.columnsFormattingRules.find(
			it => it.targetColumn === column.header
		);
		if (
			rule &&
			PhysicianExamsComponent.isConditionFilled(
				colType,
				rule.formatRule,
				row[column.label],
				rule.primaryFormatValue,
				rule.secondaryFormatValue
			)
		)
			return getDisplayStyle(rule.formattingStyle);
	}

	getColumnBooleanTextStyle(
		header: string,
		cellValue: any,
		displayMode = 'TEXT'
	): any {
		const rules = this.columnFormattingRules(header);

		const rule = rules.find(
			it => it.primaryFormatValue === cellValue.toString()
		);
		const style = rule ? rule.formattingStyle : null;

		const displayStyle = getDisplayStyle(style);

		if (rule && rule.formattingStyle.displayMode === displayMode)
			return displayStyle;
	}

	addComment(row: WorkflowItem) {
		this.dialog
			.open(CommentsComponent, {
				data: { username: this.user.username, comment: row.noteAlert },
				width: '400px',
				disableClose: true,
			})
			.afterClosed()
			.subscribe(comments => {
				if (comments && comments === 'dismiss') return;
				this.reportingService
					.saveNoteAlert(row.reportingTaskId, comments)
					.subscribe(_ =>
						this.snack.open(
							this.translate.instant('COMMENT_SAVED'),
							'OK',
							{ duration: 2000 }
						)
					);
			});
	}

	getConnectedIcon(header: string, cellValue: string): any {
		const rules = this.columnFormattingRules(header);
		const rule = rules.find(it => it.targetColumn === header);
		return rule && cellValue ? rule.formattingStyle.icon : '';
	}

	private getViewers() {
		this.setting.getViewers().subscribe(res => {
			this.viewers = res.filter(it => !it.defaultViewer);
			this.defaultViewer = res.filter(v => v.defaultViewer)[0];
			this.osirixViewers = res.filter(v => v.osirix);
			this.meddreamViewer = res.filter(
				v =>
					v.name === 'MEDDREAM' ||
					v.name.toLowerCase().includes('pro')
			)[0];
		});
	}

	exportExcel() {
		this._service
			.exportToExcel(
				this.query.getValue(),
				this.physicianChange.getValue()
			)
			.subscribe(res => {
				const blob = new Blob([res], {
					type: 'application/vnd.ms-excel',
				});
				const file = new File([blob], 'workflow.xlsx', {
					type: 'application/vnd.ms-excel',
				});

				FileSaver.saveAs(file);
			});
	}

	formatNumeral(numValue: any, comma: boolean = false): any {
		return numeral(numValue).format(`${comma ? this.numberFormat : '0,0'}`);
	}

	public get viewerOpen(): boolean {
		return !!window['viewerWindow'];
	}

	openInOsirix(row, aeTitle, multiple: boolean) {
		if (multiple)
			row.workflowItems
				.filter(w => w.imagesAvailables && w.studyInstanceUID)
				.forEach(it =>
					this.launchOsirix(
						aeTitle,
						it.patientID,
						row.studyInstanceUID
					)
				);
		else this.launchOsirix(aeTitle, row.patientID, row.studyInstanceUID);
	}

	notPaid(row: any): boolean {
		return ['NOT_PAID', 'EXEMPT'].includes(row.paymentStatus);
	}

	openInWebViewer(
		row: any,
		viewer: ViewerDTO,
		replace: boolean = true,
		multiple: boolean
	) {
		if (multiple) {
			this.workflowService
				.findExamsHistory(row.patientID)
				.subscribe(history => {
					const studyInstanceUIDs =
						history.studyInstanceUIDs ||
						row.workflowItems
							.filter(
								w => w.imagesAvailables && w.studyInstanceUID
							)
							.map(s => s.studyInstanceUID)
							.join(',');

					this.openViewerAndReport(
						row,
						viewer,
						replace,
						studyInstanceUIDs
					);
				});
		} else {
			this.openViewerAndReport(
				row,
				viewer,
				replace,
				row.studyInstanceUID
			);
		}
	}

	openStudy = (
		row: any,
		viewer: any,
		multiple?: boolean,
		replace: boolean = true
	) => this.openViewer(row, viewer, replace, multiple);

	public isRemoteEyeViewer = (): boolean => this._config.isRemoteEyeViewer;

	public launchRELite(studyUIDs?: any): void {
		LPW.REInt.popupRELite({
			username: this._config.remoteEyeUsername,
			password: this._config.remoteEyePassword,
			accNumsList: '',
			studyUIDsList: studyUIDs,
			rows: 1,
			columns: 2,
		});
	}

	getColumnDisplayMode(header: string): DisplayMode {
		const rules = this.columnFormattingRules(header);
		const rule = rules[0];
		return rule ? rule.formattingStyle.displayMode : DisplayMode.TEXT;
	}

	getColumnFormattingIcon(header: string, cellValue: any): any {
		const rules = this.columnFormattingRules(header);
		const rule = rules.find(
			it => it.primaryFormatValue === cellValue.toString()
		);
		return rule && rule.primaryFormatValue === cellValue.toString()
			? rule.formattingStyle.icon
			: '';
	}

	private buildWorkflowFilter = (value: any) => {
		this.todayFormat =
			value.period === 'TODAY' ? 'HH:mm' : this._config.dateTimeFormat;

		const startDate = moment(value.startDate).isValid()
			? moment(value.startDate)
			: moment().subtract(10, 'year');
		const endDate = moment(value.endDate).isValid()
			? moment(value.endDate)
			: moment().add(10, 'd');

		const start = startDate.format('YYYYMMDD');
		const end = endDate.format('YYYYMMDD');

		this.currentDate =
			value.period === 'OT'
				? ''
				: start === end
					? startDate.format(
							this._config.appLang === 'en' ? 'LL' : 'LLLL'
						)
					: DateUtils.formatRange(
							startDate,
							endDate,
							this._config.appLang
						);

		this.wf.key = value.key;
		this.wf.dateRange = `${start}-${end}`;
		this.wf.modalities = value.modality ? value.modality.join('-') : 'ALL';
		this.wf.reportStatuses =
			value.reportStatus && value.reportStatus.length !== 0
				? value.reportStatus.join('-')
				: 'ALL';
		this.wf.technicianId = value.technicianId;
		this.wf.physicianId = value.physicianId;
		this.wf.patientStatuses =
			value.patientStatus && value.patientStatus.length !== 0
				? value.patientStatus.join('-')
				: 'ALL';
		this.wf.paymentStatuses =
			value.paymentStatus && value.paymentStatus.length !== 0
				? value.paymentStatus.join('-')
				: 'ALL';

		this.workflowFilterSubject.next(this.wf);
	};

	private openViewerAndReport(
		row: any,
		viewer: any,
		replace: boolean,
		studyInstanceUIDs: string
	) {
		console.log(this.countOpenWindows(), window['viewerWindow']);

		this.openReport(
			row.imagesAvailables && row.workflowItems
				? row.workflowItems.filter(it => it.imagesAvailables)[0]
				: row,
			false
		);

		if (viewer === 'RELite') this.launchRELite(studyInstanceUIDs);
		else
			this.openOrUpdateViewerWindow(
				row,
				viewer,
				replace,
				studyInstanceUIDs
			);
	}

	public countOpenWindows(): number {
		return Object.keys(window).filter(it => it.includes('viewerWindow'))
			.length;
	}

	private openOrUpdateViewerWindow(
		row: any,
		viewer: any,
		replace: boolean,
		studyInstanceUIDs: string
	) {
		const params = `${viewer.name}_${studyInstanceUIDs}`;

		const viewerWindow = 'viewerWindow',
			viewerWindow2 = 'viewerWindow2';

		if (window[viewerWindow] && !window[viewerWindow].closed) {
			window[viewerWindow].focus();
			this.reportingService
				.openStudy(row.studyInstanceUID, this.user.username, replace)
				.subscribe();
		} else {
			window[viewerWindow] = window.open(
				`/external-viewer/study?param=${params}`,
				viewerWindow,
				'toolbar=0,location=0,menubar=0,left'
			);
			window[viewerWindow].addEventListener(
				'beforeunload',
				() => (window[viewerWindow] = null)
			);
		}
	}

	getColumnFormattingIconStyle(header: string, cellValue: any): any {
		const rules = this.columnFormattingRules(header);
		const rule = rules.find(
			it => it.primaryFormatValue === cellValue.toString()
		);
		if (rule) return getDisplayStyle(rule.formattingStyle);
	}

	getColumnBooleanBadgeStyle(header: string, cellValue: any): any {
		return this.getColumnBooleanTextStyle(header, cellValue, 'BADGE');
	}

	getColumnStyleDisplayMode(
		colType: ColumnDataType,
		column: TableColumn,
		row: PatientWorkflow | WorkflowItem,
		displayMode: string = DisplayMode.TEXT
	): any {
		if (!this.columnsFormattingRules) return;

		const rule = this.columnsFormattingRules.find(
			it => it.targetColumn === column.header
		);
		if (
			rule &&
			rule.formattingStyle.displayMode === displayMode &&
			PhysicianExamsComponent.isConditionFilled(
				colType,
				rule.formatRule,
				row[column.label],
				rule.primaryFormatValue,
				rule.secondaryFormatValue
			)
		)
			return getDisplayStyle(rule.formattingStyle);
	}

	getRowFormattingStyle(row: WorkflowItem | PatientWorkflow): any {
		if (!this.linesFormattingRules) return;
		return this.styles[row.accessionNumber];
	}

	private getTableViews() {
		this.setting
			.getTableViews(this.user.id, 'WORKFLOW')
			.subscribe(views => {
				const items = this.views.concat(views);
				if (this.generalSetting && !this.generalSetting.billingRequired)
					remove(items, { name: 'PAYMENTS' });
				this.views = items;
			});
	}

	private getLinesFormattingStyles() {
		if (!this.linesFormattingRules) return;

		this.linesFormattingRules.forEach(rule => {
			const column = this.workflowTableConfig.tableColumns.find(
				it => it.header === rule.targetColumn
			);
			this.dataSource.data.forEach(row => {
				if (
					PhysicianExamsComponent.isConditionFilled(
						column.type,
						rule.formatRule,
						row[column.label],
						rule.primaryFormatValue,
						rule.secondaryFormatValue
					)
				)
					this.styles[row.accessionNumber] = getDisplayStyle(
						rule.formattingStyle
					);
			});
		});
	}

	private dispatchRules(defaultRules: FormattingRule[]) {
		const rules = StringUtils.groupBy(defaultRules, 'appliedTo');
		this.linesFormattingRules = rules['ROW'];
		this.columnsFormattingRules = rules['COLUMN'];
	}

	calculateTotal(label: string): any {
		if (label === 'patientName')
			return 'Total patients: ' + this.formatNumeral(this.patientsCount);
	}

	private openInRadiant(viewer: ViewerDTO, row: any, multiple = false) {
		const radiantUrl = viewer.remotePath + 'n=pstv&v=0020000D&v=%22';
		if (multiple) {
			this.workflowService
				.findExamsHistory(row.patientID)
				.subscribe(history => {
					const studyInstanceUIDs =
						history.studyInstanceUIDs ||
						row.workflowItems
							.filter(
								w => w.imagesAvailables && w.studyInstanceUID
							)
							.map(s => s.studyInstanceUID)
							.join('%22&n=pstv&v=0020000D&v=%22');

					this.openReport(
						row.imagesAvailables
							? row.workflowItems.filter(
									it => it.imagesAvailables
								)[0]
							: row,
						false
					);
					open(radiantUrl + studyInstanceUIDs + '%22');
				});
		} else {
			this.openReport(row, false);
			open(radiantUrl + row.studyInstanceUID + '%22');
		}
	}

	getNoteAlert(note: string): string {
		let tooltip;
		const text = note.split('|');
		if (text.length !== 0) {
			tooltip = text
				.map(t => {
					const tokens = t.split(';');
					return tokens.length > 1
						? `- ${tokens[1].toUpperCase()}:\n${tokens[2]}`
						: '-';
				})
				.join('\n');
		}
		return tooltip.indexOf(undefined) !== -1 ? '' : tooltip;
	}

	private openViewer(row, viewer, replace = true, multiple: boolean = false) {
		if (viewer === 'RELite') {
			if (multiple) {
				this.workflowService
					.findExamsHistory(row.patientID)
					.subscribe(history => {
						const studyInstanceUIDs =
							history.studyInstanceUIDs ||
							row.workflowItems
								.filter(
									w =>
										w.imagesAvailables && w.studyInstanceUID
								)
								.map(s => s.studyInstanceUID)
								.join(',');

						this.openViewerAndReport(
							row,
							viewer,
							replace,
							studyInstanceUIDs
						);
					});
			} else {
				this.openViewerAndReport(
					row,
					viewer,
					replace,
					row.studyInstanceUID
				);
			}
		} else if (viewer.remotePath.startsWith('radiant'))
			this.openInRadiant(viewer, row, multiple);
		else if (!viewer.osirix)
			this.openInWebViewer(row, viewer, replace, multiple);
		else this.openInOsirix(row, viewer, multiple);
	}

	private subscribeToWsTopic(): Subscription {
		return this.wsService
			.observeTopic('workflow')
			.pipe(delay(1000))
			.subscribe({
				next: res => {
					if (res.topic === 'workflow')
						this.workflowFilterSubject.next(this.wf);
				},
				error: err => console.log(err),
				complete: () => console.log('complete'),
			});
	}

	printAttestation(row: any) {
		const matSnackBarRef = this.snack.open(
			this.translate.instant('PRINTING_IN_PROGRESS'),
			'',
			{ duration: 10000 }
		);
		this.shared
			.printAttestation(row.id)
			.subscribe(_ => matSnackBarRef.dismiss());
	}

	generateEfactUrl(row) {
		const ans = [
			row.accessionNumber,
			...row.workflowItems.map(it => it.accessionNumber),
		].join('@');
		this.scheduler
			.generateEfactUrl(ans)
			.pipe(first())
			.subscribe(efact => open(efact.url));
	}

	eFactActivated = () => this._config.eFactActivated;
}
