import {
	BehaviorSubject,
	fromEvent as observableFromEvent,
	last,
	merge,
	of as observableOf,
	Subject,
} from 'rxjs';

import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	map,
	startWith,
	switchMap,
} from 'rxjs/operators';
import {
	AfterViewInit,
	Component,
	ElementRef,
	inject,
	OnInit,
	ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
	DeleteConfirmComponent,
	LocalStorageService,
	ReferringPhysicianAddComponent,
	SharedService,
} from '../../shared';
import { PHYSICIAN_COLUMNS } from './table-conf';
import { rowsAnimation } from '../../animations';
import FileSaver from 'file-saver';
import { MessageDialogComponent } from '../../shared/message-dialog/message-dialog.component';
import { Profile, ReferringPhysicianDTO } from '../../model';
import { get, map as _map, sortBy, union } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';
import { SelectionModel } from '@angular/cdk/collections';
import { SelectDialogComponent } from '../../shared/select-dialog/select-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'ft-physicians-list',
	templateUrl: './physicians-list.component.html',
	styleUrls: ['./physicians-list.component.scss'],
	animations: [rowsAnimation],
})
export class PhysiciansListComponent implements OnInit, AfterViewInit {
	selection = new SelectionModel<ReferringPhysicianDTO>(true, []);

	dataSource = new MatTableDataSource<any>();
	resultsLength = 0;
	isLoadingResults = true;
	isRateLimitReached = false;

	filterChange = new BehaviorSubject('');
	editChange = new BehaviorSubject('');

	displayedColumns = [];
	columnsToDisplay = [];
	availableColumns = [];

	private readonly user: any;

	@ViewChild('drawer', { static: true }) drawer: MatDrawer;

	@ViewChild('filter', { static: true }) filter: ElementRef;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	public downloading: boolean = false;
	styles: any = {};
	private physicianSubject: Subject<any> = new Subject<any>();
	physician: any;
	targetTask: 'permissions' | 'exams';
	drawerWidth: string = '80vw';
	drawerMode: 'over' | 'push' | 'side' = 'over';

	profile: Profile;

	private _snackBar = inject(MatSnackBar);

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	toggleAllRows() {
		if (this.isAllSelected()) {
			this.selection.clear();
			return;
		}

		this.selection.select(...this.dataSource.data);
	}

	/** The label for the checkbox on the passed row */
	checkboxLabel(row?: any): string {
		if (!row) {
			return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
		}
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
	}

	constructor(
		private shared: SharedService,
		private dialog: MatDialog,
		private route: ActivatedRoute,
		private localStorage: LocalStorageService
	) {
		this.user = get(this.route.snapshot.data, 'user');
		this.profile = this.user?.profile;

		this.displayedColumns = PHYSICIAN_COLUMNS;
		this.availableColumns = sortBy(
			PHYSICIAN_COLUMNS.filter(item => !item.hidden),
			'order'
		);

		this.setColumnsToDisplay(this.availableColumns, 'Physicians');

		this.physicianSubject.subscribe(data => {
			this.physician = data.row;
			this.targetTask = data.target;
		});
	}

	ngOnInit() {
		this.filter.nativeElement.focus();
	}

	setColumnsToDisplay(columns, key: string) {
		this.localStorage.setItem(key, columns);

		this.columnsToDisplay = _map(
			columns.filter(c => !c.hidden),
			'label'
		);
		this.columnsToDisplay = union(['select'], this.columnsToDisplay, [
			'action',
		]);
	}

	trackByLastName(index: number, item: any): string {
		return item.lastName;
	}

	ngAfterViewInit() {
		observableFromEvent(this.filter.nativeElement, 'keyup')
			.pipe(debounceTime(400), distinctUntilChanged())
			.subscribe(() => {
				if (!this.dataSource) return;
				this.paginator.pageIndex = 0;
				this.filterChange.next(this.filter.nativeElement.value);
			});

		this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

		merge(
			this.sort.sortChange.asObservable(),
			this.paginator.page.asObservable(),
			this.editChange,
			this.filterChange
		)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;
					return this.shared.queryReferringPhysicians(
						this.paginator.pageSize,
						this.paginator.pageIndex,
						this.sort.active,
						this.sort.direction,
						this.filter.nativeElement.value
					);
				}),
				map(data => {
					this.isLoadingResults = false;
					this.isRateLimitReached = false;
					this.resultsLength = data['totalElements'];
					return data['content'];
				}),
				catchError(() => {
					this.isLoadingResults = false;
					this.isRateLimitReached = true;
					return observableOf([]);
				})
			)
			.subscribe(data => (this.dataSource.data = data));
	}

	editReferringPhysician(item: ReferringPhysicianDTO): void {
		this.dialog
			.open(ReferringPhysicianAddComponent, { data: item })
			.afterClosed()
			.subscribe(res => {
				if (res) this.editChange.next(res);
			});
	}

	deleteReferringPhysician(item: ReferringPhysicianDTO): void {
		this.shared
			.isReferringPhysicianUsed(item.id)
			.pipe(last())
			.subscribe(value => {
				if (value)
					this.dialog.open(MessageDialogComponent, {
						data: 'CANNOT_DELETE_USED',
					});
				else {
					this.dialog
						.open(DeleteConfirmComponent)
						.afterClosed()
						.subscribe(ok => {
							if (ok) {
								this.shared
									.deleteReferringPhysician(item)
									.subscribe(res => {
										if (res) {
											this._snackBar.open(
												'Deleted !',
												'Ok',
												{ duration: 2000 }
											);
											this.editChange.next(null);
										}
									});
							}
						});
				}
			});
	}

	exportPhysicians(): void {
		this.downloading = true;

		this.shared.exportPhysicians().subscribe({
			next: data => {
				this.downloading = false;

				const blob = new Blob([data], {
					type: 'application/vnd.ms-excel',
				});
				const file = new File([blob], 'physicians.xlsx', {
					type: 'application/vnd.ms-excel',
				});

				FileSaver.saveAs(file);
			},
			error: _ => (this.downloading = false),
		});
	}

	managePhysicianPermissions(row: any) {
		this.openDrawer(row, 'side', '320px', 'permissions');
	}

	openPhysicianExams(row: any) {
		if (this.drawerMode === 'side' && this.drawer.opened) return;
		this.openDrawer(row, 'over', '80vw');
	}

	mergePhysicians() {
		this.dialog
			.open(SelectDialogComponent, {
				minWidth: '360px',
				data: { items: this.selection.selected, entity: 'physician' },
			})
			.afterClosed()
			.subscribe(res => {
				if (res) {
					const physiciansToDelete = this.selection.selected.filter(
						it => it.id !== res.id
					);

					const physiciansSecondAddresses = [];
					physiciansToDelete.forEach(it => {
						if (it.primaryAddress)
							physiciansSecondAddresses.push({
								label: `${it.id}`,
								value: it.primaryAddress,
							});
						if (it.secondaryAddresses)
							physiciansSecondAddresses.push(
								...JSON.parse(it.secondaryAddresses)
							);
					});
					res.secondaryAddresses = res.secondaryAddresses
						? JSON.stringify([
								JSON.parse(res.secondaryAddresses),
								...physiciansSecondAddresses,
							])
						: JSON.stringify(physiciansSecondAddresses);

					if (!res.address && physiciansSecondAddresses.length != 0)
						res.address = physiciansSecondAddresses[0].value;

					const physicianIds = physiciansToDelete
						.map(it => it.id)
						.join('_');
					this.shared
						.mergePhysicians(res, physicianIds)
						.subscribe(done => {
							if (done) {
								this.filterChange.next(this.filterChange.value);
								this.selection.clear();
							}
						});
				}
			});
	}

	private openDrawer(
		row: any,
		mode: 'over' | 'push' | 'side',
		width: string,
		target: string = 'exams'
	) {
		this.drawerWidth = width;
		this.drawerMode = mode;
		if (this.drawer.opened) {
			this.physicianSubject.next({ target, row });
		} else {
			this.drawer.open().then(_ => {
				this.physicianSubject.next({ target, row });
			});
		}
	}
}
