import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserResolver } from '../user.resolver';
import { PhysiciansListComponent } from './physicians-list/physicians-list.component';

const routes: Routes = [
	{
		path: '',
		resolve: { user: UserResolver },
		component: PhysiciansListComponent,
		children: [
			{ path: '', pathMatch: 'full', redirectTo: 'physicians-list' },
			{ path: 'physicians-list', component: PhysiciansListComponent },
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class PhysiciansRoutingModule {}
