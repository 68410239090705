import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WORKFLOW_URL } from '../urls';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class PhysicianService {
	private _http = inject(HttpClient);

	getWorkflow(
		pageSize: number,
		pageIndex: number,
		sort: string,
		direction: string,
		filter: string,
		physicianId: number
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: `${sort},${direction}`,
			filter,
			physicianId,
		};
		return this._http.get(`${WORKFLOW_URL}/physician-exams`, { params });
	}

	countPatients(filter: string, physicianId: number): Observable<number> {
		return this._http.get<number>(`${WORKFLOW_URL}/total-patients`, {
			params: { filter, physicianId },
		});
	}

	exportToExcel(query: string, physicianId): Observable<Blob> {
		const params = { keys: query, physicianId };
		return this._http.get(`${WORKFLOW_URL}/exportToExcel`, {
			params: params,
			responseType: 'blob',
		});
	}
}
