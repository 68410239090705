<mat-card class="fx-card fx-fill-height fx-layout-column-nowrap">
	<mat-toolbar
		color="primary"
		class="fx-card-toolbar fx-height-42 fx-layout-row-nowrap fx-content-space-between fx-items-align">
		<mat-icon fontSet="mdi" fontIcon="mdi-account-group"></mat-icon>
		<span>{{ 'REFERRING_PHYSICIANS' | translate }}</span>

		<span class="fx-grow-1"></span>

		@if (selection.hasValue() && selection.selected.length > 1) {
			<button mat-button (click)="mergePhysicians()">
				<mat-icon
					class="merge-icon"
					fontIcon="mdi-merge"
					fontSet="mdi"></mat-icon>
				{{ 'MERGE' | translate }}
			</button>
		}

		@if (profile && profile.exportPhysicians !== 'NONE') {
			<button
				(click)="exportPhysicians()"
				[matTooltip]="'EXPORT_TO_EXCEL' | translate"
				mat-button>
				<mat-icon
					class="excel-icon-gradient"
					fontIcon="mdi-microsoft-excel"
					fontSet="mdi"></mat-icon>
				{{ 'EXPORT' | translate }}
			</button>
		}

		<button
			[matTooltip]="'ADD_NEW' | translate"
			mat-button
			(click)="editReferringPhysician({})">
			<mat-icon
				class="add-icon-gradient"
				fontIcon="mdi-plus"
				fontSet="mdi"></mat-icon>
			{{ 'ADD_NEW' | translate }}
		</button>
	</mat-toolbar>

	<div
		class="fx-search-area fx-layout-row fx-content-start fx-items-center fx-gap-8 fx-padding-8">
		<mat-form-field subscriptSizing="dynamic" class="fx-fill-width">
			<mat-icon
				matIconPrefix
				fontIcon="mdi-magnify"
				fontSet="mdi"></mat-icon>
			<input
				matInput
				#filter
				placeholder="{{ 'SEARCHING' | translate }}" />
		</mat-form-field>
	</div>

	<mat-drawer-container [hasBackdrop]="false" class="fx-grow-1">
		<mat-drawer
			#drawer
			[style.margin-top.px]="-1"
			[style.width]="drawerWidth"
			[mode]="drawerMode"
			position="end">
			<div class="drawer-container">
				@switch (targetTask) {
					@case ('permissions') {
						<ft-physician-permissions
							(closeEvent)="drawer.toggle(); targetTask = null"
							[physician]="physician"></ft-physician-permissions>
					}
					@case ('exams') {
						<ft-physician-exams
							(closeEvent)="drawer.toggle(); targetTask = null"
							[physician]="physician"></ft-physician-exams>
					}
				}
			</div>
		</mat-drawer>
		<mat-drawer-content>
			<div class="fx-layout-column-nowrap fx-fill-height">
				<div class="fx-fill-height fx-overflow-auto">
					<mat-table
						[dataSource]="dataSource"
						class="fire-table"
						matSort
						matSortActive="lastName"
						matSortDirection="desc"
						[trackBy]="trackByLastName">
						<!-- Checkbox Column -->
						<ng-container matColumnDef="select">
							<mat-header-cell *matHeaderCellDef>
								<mat-checkbox
									(change)="$event ? toggleAllRows() : null"
									[checked]="
										selection.hasValue() && isAllSelected()
									"
									[indeterminate]="
										selection.hasValue() && !isAllSelected()
									"
									[aria-label]="checkboxLabel()">
								</mat-checkbox>
							</mat-header-cell>
							<mat-cell mat-cell *matCellDef="let row">
								<mat-checkbox
									(click)="$event.stopPropagation()"
									(change)="
										$event ? selection.toggle(row) : null
									"
									[checked]="selection.isSelected(row)"
									[aria-label]="checkboxLabel(row)">
								</mat-checkbox>
							</mat-cell>
						</ng-container>

						<!--Table-->
						@for (col of displayedColumns; track col) {
							<ng-container
								[class.hidden]="col.hidden"
								matColumnDef="{{ col.label }}">
								<mat-header-cell
									*matHeaderCellDef
									mat-sort-header>
									{{ col.header | translate }}
									@if (
										col.unit !== 'date' &&
										col.unit !== 'color'
									) {
										<span>{{ col.unit }}</span>
									}
								</mat-header-cell>

								@switch (col.unit) {
									@case ('date') {
										<mat-cell *matCellDef="let row">
											{{
												row[col.label]
													| date: 'dd/MM/yyyy'
											}}
										</mat-cell>
									}
									@case ('color') {
										<mat-cell *matCellDef="let row">
											<mat-icon
												[ngStyle]="{
													background:
														'linear-gradient(45deg, black, ' +
														row[col.label] +
														', white)',
													'-webkit-background-clip':
														'text',
													'-webkit-text-fill-color':
														'transparent'
												}"
												fontSet="mdi"
												fontIcon="mdi-checkbox-blank-circle"></mat-icon>
										</mat-cell>
									}
									@default {
										<mat-cell *matCellDef="let row">
											{{ row[col.label] }}</mat-cell
										>
									}
								}
							</ng-container>
						}

						<!--actions-->
						<ng-container matColumnDef="action" [stickyEnd]="true">
							<mat-header-cell
								*matHeaderCellDef></mat-header-cell>
							<mat-cell *matCellDef="let row">
								@if (profile?.viewExams !== 'NONE') {
									<button
										mat-icon-button
										[matTooltip]="
											'LIST_OF_EXAMS' | translate
										"
										(click)="
											$event.stopPropagation();
											openPhysicianExams(row)
										">
										<mat-icon
											class="exams-icon-gradient"
											fontIcon="mdi-file-multiple"
											fontSet="mdi"></mat-icon>
									</button>
								}
								@if (profile?.managePermissions !== 'NONE') {
									<button
										mat-icon-button
										[matTooltip]="
											'MANAGE_PERMISSIONS' | translate
										"
										(click)="
											$event.stopPropagation();
											managePhysicianPermissions(row)
										">
										<mat-icon
											class="access-icon-gradient"
											fontIcon="mdi-account-cog"
											fontSet="mdi"></mat-icon>
									</button>
								}
								@if (profile?.editPhysician !== 'NONE') {
									<button
										mat-icon-button
										[matTooltip]="'EDIT' | translate"
										(click)="
											$event.stopPropagation();
											editReferringPhysician(row)
										">
										<mat-icon
											class="edit-icon-gradient"
											fontIcon="mdi-pencil"
											fontSet="mdi"></mat-icon>
									</button>
								}
								@if (profile?.deletePhysician !== 'NONE') {
									<button
										mat-icon-button
										[matTooltip]="'DELETE' | translate"
										(click)="
											$event.stopPropagation();
											deleteReferringPhysician(row)
										">
										<mat-icon
											class="delete-icon-gradient"
											fontIcon="mdi-delete"
											fontSet="mdi"></mat-icon>
									</button>
								}
							</mat-cell>
						</ng-container>

						<!--header-->
						<mat-header-row
							*matHeaderRowDef="
								columnsToDisplay
							"></mat-header-row>
						<mat-row
							[@rowsAnimation]=""
							*matRowDef="let row; columns: columnsToDisplay"
							(click)="openPhysicianExams(row)"
							[ngClass]="{
								'fire-row': true,
								hide: row.hidden
							}"></mat-row>
					</mat-table>
					<div
						[style.display]="resultsLength === 0 ? '' : 'none'"
						class="fx-table-empty">
						@if (isLoadingResults) {
							<div>
								<mat-spinner
									[diameter]="50"
									[strokeWidth]="3"
									color="warn"></mat-spinner>
							</div>
						} @else {
							<button
								(click)="editReferringPhysician(null)"
								color="primary"
								mat-button
								class="table-btn-new">
								<mat-icon
									fontIcon="mdi-plus"
									fontSet="mdi"></mat-icon>
								{{ 'NEW' | translate }}
							</button>
						}
					</div>
				</div>
				<mat-paginator
					[length]="resultsLength"
					[pageIndex]="0"
					[pageSize]="25"
					[pageSizeOptions]="[5, 10, 25, 50, 100]"
					[showFirstLastButtons]="true">
				</mat-paginator>
			</div>
		</mat-drawer-content>
	</mat-drawer-container>
</mat-card>

@if (downloading) {
	<div
		class="fx-layout-row-nowrap fx-content-center fx-items-center fixed fx-h-fill fx-w-fill"
		style="top: 0; left: 0; background: rgba(0, 0, 0, 0.4); z-index: 10">
		<div
			class="fx-layout-row-nowrap fx-content-center fx-items-center fx-gap-12"
			style="
				height: 86px;
				width: 360px;
				border-radius: 8px;
				background: rgb(255 255 255);
				color: #585858;
			">
			<mat-spinner [diameter]="30" [strokeWidth]="2"></mat-spinner>
			<h3>{{ 'EXPORTING' | translate }}</h3>
		</div>
	</div>
}
