<div>
	<mat-toolbar
		class="sc-toolbar2"
		style="background: rgb(0 75 110)"
		class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-4">
		<div class="title">
			<mat-icon fontSet="mdi" fontIcon="mdi-account-cog"></mat-icon>
			<span>{{ physician.fullName }}</span>
		</div>

		<span class="fx-grow-1"></span>
		<button mat-button (click)="closeEvent.emit(true)">
			<mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
			{{ 'CLOSE' | translate }}
		</button>
	</mat-toolbar>

	<div class="permissions-container">
		<div
			style="
				color: grey;
				font-style: italic;
				padding: 12px;
				font-size: 12px;
			">
			No implementation
		</div>
	</div>
</div>
