import { ColumnDataType, TableColumn } from '../../model';

export const PHYSICIAN_COLUMNS: TableColumn[] = [
	{
		label: 'id',
		value: 'id',
		hidden: true,
		header: 'ID',
		sortField: 'id',
		sortable: true,
		searchable: true,
		required: false,
		order: 1,
		id: 0,
		type: ColumnDataType.NUMERIC,
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'fullName',
		value: 'fullName',
		hidden: false,
		sortField: 'full_name',
		header: 'FULL_NAME',
		sortable: false,
		searchable: true,
		required: false,
		order: 2,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: null,
	},
	{
		label: 'email',
		value: 'email',
		hidden: false,
		sortField: 'email',
		header: 'EMAIL',
		sortable: true,
		searchable: true,
		required: false,
		order: 4,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: null,
	},
	{
		label: 'phone',
		value: 'phone',
		hidden: false,
		sortField: 'phone',
		header: 'PHONE',
		sortable: true,
		searchable: true,
		required: false,
		order: 5,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: null,
	},
	{
		label: 'codeAnam',
		value: 'codeAnam',
		hidden: false,
		sortField: 'code_anam',
		header: 'CODE_PRO',
		sortable: true,
		searchable: true,
		required: false,
		order: 6,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: null,
	},
	{
		label: 'primaryAddress',
		value: 'primaryAddress',
		hidden: false,
		sortField: 'primaryAddress',
		header: 'ADDRESS',
		sortable: true,
		searchable: true,
		required: false,
		order: 8,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: null,
	},
];

export const EXCLUDED_WORKFLOW_COLUMNS = [
	'appointmentDateTime',
	'noteAlert',
	'count',
	'referringPhysician',
	'insuranceName',
	'editingState',
	'leftAmount',
	'globalDiscount',
	'paidAmount',
	'totalAmount',
	'paymentStatus',
	'sideEffects',
	'urgent',
];
