import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';

@Component({
	selector: 'ft-physician-permissions',
	templateUrl: './physician-permissions.component.html',
	styleUrls: ['./physician-permissions.component.scss'],
})
export class PhysicianPermissionsComponent implements OnChanges {
	@Input() physician: any;
	@Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();

	ngOnChanges(changes: SimpleChanges): void {
		const { physician } = changes;
	}
}
